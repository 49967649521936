.plans .plan:last-of-type .pillow:first-of-type::before{
    content: "Recommended";
    position: absolute;
    right:20px;
    top: -10px;
    padding: 3px 10px;
    border-radius: 3px;
    font-size: 18px;
    color: purple;
    background-color: rgb(247, 239, 248);
    border: 2px solid purple;
    font-weight: 600;
    transform: scale(1);
    animation: pulse-purple 2s infinite;
}
@keyframes pulse-purple {
    0% {
      transform: scale(0.97);
      box-shadow: 0 0 0 0px rgba(142, 68, 173, 0.5);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(142, 68, 173, 0);
    }

    100% {
      transform: scale(0.97);
      box-shadow: 0 0 0 0 rgba(142, 68, 173, 0);
    }
  }

.plans{
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    justify-content:center;
    flex-basis: 50%;
    margin:5px;
    flex-direction: row;
}
.plan{
    position: relative;
    margin:5px;
    flex-basis: 50%;
}

.plan h2{
    font-family: 'Times New Roman', Times, serif;
    font-size:25px;
    line-height: 1.5;
    min-height: 80px;
    padding: 0;
    margin:0px 0 3px 0;
}
.description{
    font-size: 16px;
    margin: 7px 0;
}
.pillow{
    font-size:14px;
    padding:20px;
    margin: 2px 0;
    background-color: #ffffff;
}

.pillow .h3{
    font-size:18px;
    font-weight: 400;
}
.pillow ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-inline-start: 20px;
}
.pillow ul{
    color:rgb(105, 105, 109);
}
.pillow li li{
    margin-top: 4px;
    line-height: 1.8;
}
.pillow:hover ul{
    color:black;
}
@media screen and (max-width: 650px) {
    .plans{
        flex-direction: column;
    }
  }
  .pillowHeader{
    display: flex;
  }
  .pillowHeader svg{
    margin-right: 15px;
  }
  .topbutton {
    padding-right: 10px;
    font-size: 14px;
    color:black;
    text-align: right;
  }
  .topbutton a{
    color:black;
  }